import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Container from '~components/Container';
import RichText from '~components/RichText';
import Link from '~components/Link';
import { generateUrlFriendlyIdFromTitle } from '~utils/url-helper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color,
    position: 'relative',
    paddingTop: '32px',
    paddingBottom: '32px',
    scrollMarginTop: '100px'
  },
  title: {
    width: '100%',
    paddingTop: '32px',
    marginBottom: 0,
    borderTop: '1px #D4D4D4 solid',
    '& > *': {
      maxWidth: '700px',
      color: 'black',
      fontSize: 40,
      fontFamily: 'Roboto',
      fontWeight: '600',
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        textAlign: 'left'
      }
    }
  },
  pages: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    paddingTop: '32px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '16px',
      alignItems: 'start'
    }
  },
  link: {
    color: '#158EFF',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontWeight: '400',
    maxWidth: '320px',
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      margin: 0
    }
  }
}));

const ArticleRelatedPages = ({ section_title, pages, background_color, containerSize }) => {
  const location = useLocation();
  const classes = useStyles({ background_color });
  const sectionId = useMemo(
    () => generateUrlFriendlyIdFromTitle(section_title?.text),
    [section_title]
  );

  useEffect(() => {
    // expand when url hash has a section id
    if (location?.hash && sectionId === location.hash.substring(1)) {
      navigate(location?.hash);
    }
  }, [location?.hash, sectionId]);

  return (
    <div className={classes.root} id={sectionId}>
      <Container maxWidth={containerSize}>
        <RichText externalClassName={classes.title} html={section_title?.html} />
        <div className={classes.pages}>
          {pages.map(page => (
            <Link key={page.related_page_title} to={page.related_page_url} className={classes.link}>
              {page.related_page_title}
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
};

ArticleRelatedPages.propTypes = {
  background_color: PropTypes.string,
  section_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      related_page_title: PropTypes.string,
      related_page_url: PropTypes.string
    })
  ),
  containerSize: PropTypes.string
};

ArticleRelatedPages.defaultProps = {
  pages: [],
  background_color: '#F3F3F3',
  containerSize: 'md'
};

export default ArticleRelatedPages;
